import React, {useContext, useEffect, useMemo, useState} from 'react';
import cl from './styles/AdminUsers.module.css'
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import Config from '../cfg'
import MyInput from "../UI/input/MyInput";
import Loader from "../UI/loader/Loader";
import {Link} from "react-router-dom";
import {Context} from "../index";
import MyModal from "../UI/modal/MyModal";

const AdminUsers = () => {

    const [users, setUsers] = useState([])
    const [changeValue, setChangeValue] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const {store} = useContext(Context)
    const [backendResponse, setBackendResponse] = useState('')
    const [modalActive, setModalActive] = useState(false)

    const filteredUsers = useMemo(() => {
        if (changeValue === 'clients') {
            return users.filter((user) => (
                user.role_id === Config.clientID
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else if (changeValue === 'verify') {
            return users.filter((user) => (
                !user.is_verified
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else if (changeValue === 'group_leaders') {
            return users.filter((user) => (
                user.role_id === Config.consultantID && user.type_of_activity === 'group_leader'
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else if (changeValue === 'rk') {
            return users.filter((user) => (
                user.role_id === Config.consultantID && user.type_of_activity === 'rk'
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else if (changeValue === 'psycho') {
            return users.filter((user) => (
                user.role_id === Config.consultantID && user.type_of_activity === 'psycho'
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else if (changeValue === 'admins') {
            return users.filter((user) => (
                user.role_id === Config.adminID
                &&
                `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
                )
            ))
        }
        else return users.filter((user) => (
            `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
            )
        ))
    }, [changeValue, users, searchQuery]);

    const [fetchUsers, usersIsLoading, usersError] = useFetching(async () => {
        if (store.isSuperUser) {
            const response = await AdminService.getAllUsers()
            setUsers(response)
        }
        else {
            const response = await AdminService.getUsers()
            setUsers(response)
        }
    })

    useEffect(() => {
        fetchUsers()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (usersError.length) {
            setBackendResponse(usersError)
            setModalActive(true)
        }
    }, [usersError]);

    return (
        <div className={cl.content}>
            
            <h3>Список пользователей</h3>

            <div className={cl.radio_change}>
                <div>
                    <input type="radio" name='change' value='all' id='all' defaultChecked={true}
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="all">Все</label>
                </div>

                <div>
                    <input type="radio" name='change' value='verify' id='verify'
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="verify">Ожидают верификации</label>
                </div>

                <div>
                    <input type="radio" name='change' value='clients' id='clients'
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="clients">Клиенты</label>
                </div>

                <div>
                    <input type="radio" name='change' value='group_leaders' id='group_leaders'
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="group_leaders">Ведущие</label>
                </div>

                <div>
                    <input type="radio" name='change' value='rk' id='rk'
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="rk">РК</label>
                </div>

                <div>
                    <input type="radio" name='change' value='psycho' id='psycho'
                           onChange={(event) => {
                               setChangeValue(event.target.id)
                           }}
                    />
                    <label htmlFor="psycho">Психологи</label>
                </div>
                {store.isSuperUser &&
                    <div>
                        <input type="radio" name='change' value='admins' id='admins'
                               onChange={(event) => {
                                   setChangeValue(event.target.id)
                               }}
                        />
                        <label htmlFor="admins">Админы</label>
                    </div>
                }

            </div>

            <div className={cl.user_list}>
                <MyInput
                    placeholder='Поиск пользователей...'
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value)
                    }}/>
                {usersIsLoading
                    ? <div><Loader/></div>
                    : <div>
                        {filteredUsers.length ? <h3>{`Всего найдено: ${filteredUsers.length}`}</h3> : null}
                        {filteredUsers.length
                            ? filteredUsers.map((row) => (
                                <div
                                    key={row.id}>
                                    <Link
                                        to={`/admin/user/${row.id}`}
                                        className={cl.users}
                                    >{row.surname} {row.name}</Link>
                                </div>
                            ))
                            : <h4>Пользователи не найдены</h4>
                        }
                    </div>
                }
            </div>
            {modalActive
                ? <div>
                    <MyModal
                        active={modalActive}
                        setActive={setModalActive}
                        children={backendResponse}
                        />
                    </div>
                : void {}
            }
        </div>
    );
};

export default AdminUsers;