import React, {useContext, useEffect, useState} from 'react';
import ClientMeetings from "../components/ClientMeetings";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import {Context} from "../index";
import cl from './styles/ClientEntries.module.css'
import MyModal from "../UI/modal/MyModal";

const MeetingsPsycho = () => {

    const {store} = useContext(Context)
    const [possibilityOfPsychoHelp, setPossibilityOfPsychoHelp] = useState(false)

    const [fetchPossibilityPsychoHelp, possibilityPsychoHelpIsLoading, possibilityPsychoHelpError] =
        useFetching(async () => {
            const response = await ClientService.getPossibilityPsychoHelp(store.userID)
            setPossibilityOfPsychoHelp(response)
        })

    useEffect(() => {
        fetchPossibilityPsychoHelp()
    }, []);

    const [feedbackText, setFeedbackText] = useState('')
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsactive] = useState(false)

    const [fetchSendFeedback, sendFeedbackIsLoading, sendFeedbackError] =
        useFetching(async () => {
            const response = await ClientService.sendFeedback({client_id: store.userID, text: feedbackText})
            setBackendResponse('Благодарим за обратную связь!')
            setModalIsactive(true)
        })

    if (possibilityOfPsychoHelp) {
        return (
            <div>
                <ClientMeetings
                    typeOfMeeting='psycho'
                />
            </div>
        );
    }
    else {
        return (
            <div className={cl.feedback}>
                <p>
                    У вас уже запланировано 5 сессий с психологом.
                    Больше получить помощь психолога не получится.
                    Спасибо за доверие!
                </p>
                <textarea
                    placeholder='Будем благодарны, если поделитесь обратной связью: что понравилось, не понравилось, помогли ли вам встречи с психологом фонда. Вашу обратную связь увидит только администратор фонда, психологу она будет недоступна. (до 500 символов)'
                    maxLength={500}
                    onChange={(event) => {
                        setFeedbackText(event.target.value)
                    }}
                >
                </textarea>
                <button
                    onClick={() => {
                        fetchSendFeedback()
                    }}
                >Отправить</button>

                {modalIsActive
                    ? <MyModal
                        active={modalIsActive}
                        children={backendResponse}
                    />
                    : void {}
                }

            </div>
        )
    }

};

export default MeetingsPsycho;